ion-icon {
  &[class*='custom-'] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    width: 1em;
    height: 1em;
  }

  &[class*='custom-download'] {
    mask-image: url('../assets/icon/download.svg');
  }

  &[class*='custom-circle'] {
    mask-image: url('../assets/icon/circle.svg');
  }

  &[class*='custom-check'] {
    mask-image: url('../assets/icon/check.svg');
  }

  &[class*='custom-no-check'] {
    mask-image: url('../assets/icon/no-check.svg');
  }

  &[class*='custom-pending'] {
    mask-image: url('../assets/icon/pending.svg');
  }
  &[class*='custom-calculator-outline'] {
    mask-image: url('../assets/icon/calculator-outline.svg');
  }
}
