/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/*Utils */
@import './utils.scss';

// CUSTOM-ICONS
@import './theme/custom-icons.scss';

// MODALS
@import './theme/modals.scss';

* {
  font-family: 'Work Sans', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}

:root {
  --ion-color-tertiary: #0db1e6;
  --ion-color-tertiary-rgb: 0, 102, 0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #0da4d6;
  --ion-color-tertiary-tint: #12a7d9;
  --ion-color-secondary: #0560cf;
  --ion-color-secondary-rgb: 0, 102, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #014faf;
  --ion-color-secondary-tint: #0251b2;
  --ion-color-medium: #e1e4e5;
  --ion-color-medium-rgb: 225, 228, 229;
  --ion-color-medium-contrast: #e1e4e5;
  --ion-color-medium-contrast-rgb: 225, 228, 229;
  --ion-color-medium-shade: #e1e4e5;
  --ion-color-medium-tint: #e1e4e5;
  --ion-color-primary: #0047af;
  --ion-color-primary-rgb: 0, 102, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #014faf;
  --ion-color-primary-tint: #0251b2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:root {
  @import './theme/variables.scss';
  --ion-background-color: #ffffff;
  --ion-text-color: #000000;
}

$white-color: #fff;
$height100: 100%;

app-page-container {
  width: 100%;
  height: $height100;
  background: #dfdfdf;
}

ion-item::part(native) {
  background-color: #fff;
  color: #000;
  padding-left: 0;
}

$text: #000;
$text-lighter: lighten($text, 20);

ion-item ion-label {
  color: $text !important;

  &.subtitle {
    color: $text-lighter !important;
  }
}

div#ts-header > div > *:first-child {
  background-color: #e5e5e5;
}

ion-item.ts-input-container {
  ion-label span {
    color: red;
  }
}

/*Custom CSS*/

ion-button {
  --border-radius: 25px;
  --background: #0049bf;
  --color: #ffffff;

  height: 40px;
  font-size: 13px;

  &:not([fill='clear']) {
    text-transform: uppercase;
  }
}

/*Slider bullets position*/
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px;
}

.no-mt {
  margin-top: 0;
}

.no-mb {
  margin-bottom: 0;
}

.color-primary {
  color: var(--ion-color-primary);
}
.color-secondary {
  color: var(--ion-color-secondary);
}
.color-medium {
  color: var(--ion-color-medium);
}
.color-tertiary {
  color: var(--ion-color-tertiary);
}

.color-light {
  color: #6c777c;
}

.bt-line {
  border-bottom: 1px solid #ccd1d3;
}

body {
  background-color: transparent !important;
}

ion-content,
ion-content::part(background) {
  background-color: transparent !important;
  background: transparent !important;
}

.primary-bg {
  background: linear-gradient(180deg, #0047af 0%, #0361cf 100px);
}

.no-bg {
  background: var(--ion-color-primary);
}

.gradient-body {
  background: linear-gradient(355.54deg, rgba(8, 209, 255, 0.5) -14.94%, #cde7ff 31.74%, #ffffff 91.62%) !important;
}

.border-radius {
  border-radius: 10px;
}

.rounded-body {
  min-height: 20vh;
  border-radius: 15px 15px 0 0;
  background: var(--ion-background-color);
}

.photo-btn {
  ion-icon {
    color: var(--ion-color-secondary);
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccd1d3;
  box-sizing: border-box;
  border-radius: 10px;
}

.ts-body-padding-vertical {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.ts-loader .loading-wrapper {
  border-radius: 15px; /* Ajusta el valor según tu preferencia */
}

.my-custom-class {
  padding: 50px;

  .modal-wrapper {
    height: 20%;
    border-radius: 15px 15px 0 0;
    --max-height: 20%;
    bottom: 0;
    ion-content::part(scroll) {
      background: white;
    }
  }
}

.green {
  color: var(--ion-color-success);
}
.red {
  color: var(--ion-color-danger);
}

.normal-modal {
  margin: -0.4rem;

  .modal-wrapper {
    border-radius: 15px;
    --max-height: 80%;
    --max-width: 90%;
    background: none;
    position: absolute;
    bottom: 5%;
  }
}

.contact-modal-class {
  padding: 30px;

  .modal-wrapper {
    border-radius: 15px 15px 0 0;
    --max-height: 90%;
    position: absolute;
    bottom: 0;
  }

  .ion-page {
    overflow: auto;
  }
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }
  --height: auto;
  --max-width: 90%;
  --border-radius: 15px;
  .ion-page {
    position: relative;
    display: block;
    contain: content;
    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 16px;
    }
  }
}

.ts-title {
  font-size: 32px;
}

.text-bold {
  font-weight: bold;
}

.ion-no-margin-top {
  margin-top: 0;
}

.ion-no-padding-top {
  padding-top: 0;
}

.ion-no-margin-bottom {
  margin-bottom: 0;
}

.ion-no-padding-bottom {
  padding-bottom: 0;
}

.ion-no-margin-vertical {
  margin-top: 0;
  margin-bottom: 0;
}

.ion-no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0;
}

h1 {
  font-weight: 600;
  font-size: 32px;
  font-style: normal;
  line-height: 37.54px;
  margin: 0;
}

h2 {
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 18.77px;
  margin: 0;
}

.h2b {
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 18.77px;
  margin: 0;
  color: white !important;
}

h3 {
  font-weight: 400;
  font-size: 22px;
  font-style: normal;
  line-height: 25.81px;
  margin: 0;
}

h4 {
  font-weight: 700;
  font-size: 24px;
  font-style: normal;
  line-height: 28.15px;
  margin: 0;
}

h5 {
  font-weight: 400;
  font-size: 11px;
  font-style: normal;
  line-height: 12.9px;
  margin: 0;
}

h6 {
  font-weight: 600;
  font-size: 13px;
  font-style: normal;
  line-height: 15.25px;
  margin: 0;
}

p {
  font-weight: 400;
  font-size: 13px;
  font-style: normal;
  line-height: 15.25px;
}

.no-margin {
  margin: 0;
}

.ng-select .ng-select-container {
  color: rgba(0, 0, 0, 0.87);
  align-items: flex-end;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  min-height: 51.5px;
  .ng-dropdown-panel.ng-select-bottom {
    position: relative;
  }
}

.footer-actions {
  background-color: var(--ion-background-color);
  border-top: 1px solid #ccd1d3;
  justify-content: space-around;
  width: 100%;
  display: flex;

  div {
    &.actions-left {
      border-right: 1px solid #ccd1d3;
      padding: 10px 0px;
    }
    &.actions-right {
      border-left: 1px solid #ccd1d3;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0px;
    }
    text-align: center;
    flex: 1;

    .chatIcon {
      width: 25px;
      height: 25px;
      margin-left: 5px;
    }
  }
}

@each $hNumber in '1' '2' '3' '4' '5' '6' '7' {
  .ts-spacer-#{$hNumber} {
    height: #{$hNumber}vh;
  }
}

.ts-spacer {
  height: 2.5vh;
}

// ionic native components modify
ion-item {
  &.border-vertical:first-child {
    --border-width: 1px 0px 0px 0px;
  }
}

ion-item-divider {
  min-height: 2.5px;
}

//text helpers
.to-lowercase {
  text-transform: lowercase;
}

.to-uppercase {
  text-transform: uppercase;
}

.uppercase-first-letter::first-letter {
  text-transform: capitalize;
}

button.mercadopago-button {
  width: 80%;
  border-radius: 24px;
  font-size: 15px;
  margin: auto;
  background: #0047af;
}

.h-100 {
  height: 100%;
}

#customInputFile {
  position: absolute;
  opacity: 0;
}

.modal-billing {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  .modal-wrapper {
    --border-radius: 15px;
    --height: 50%;
    --width: 70%;
  }
}

.modal-baja {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  .modal-wrapper {
    --border-radius: 15px;
    --height: 40%;
    --width: 90%;
  }
}

.modal-bienvenido {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  .modal-wrapper {
    --border-radius: 15px;
    --height: 50%;
    --width: 90%;
  }
}

.modal-reca {
  margin: auto;
  z-index: 1; /* Asegura que el modal esté por encima de otros elementos */
  width: 100%; /* El modal ocupará todo el ancho */
  height: 100%; /* El modal ocupará toda la altura */
  overflow: auto; /* Añade barras de desplazamiento si el contenido es demasiado grande */
  background-color: rgba(0, 0, 0, 0.4); /* Fondo oscuro semitransparente */
  --border-radius: 15px;

  /* Centrando el texto */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  /* Añadiendo padding a los costados */
  padding: 0 20px; /* Puedes ajustar los valores de padding según necesites */
}

.modal-limit {
  --border-radius: 15px;

  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  .modal-wrapper {
    --border-radius: 15px;
    --height: 63%;
    --width: 93%;
  }
}

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover,
.sc-ion-select-popover-h ion-label.sc-ion-select-popover {
  margin-left: 15px;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.modal-iibb-register {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  .modal-wrapper {
    background: #ffffff;
    width: 310px;
    height: 300px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-md {
  white-space: normal !important;
}

.alert-radio-group.sc-ion-alert-md,
.alert-radio-group.sc-ion-alert-ios {
  padding: 15px 0;
}

.native-input.sc-ion-input-md:invalid,
.native-input.sc-ion-input-ios:invalid {
  margin-top: 10px;
  // color: #c4c4c4;
}

.native-input.sc-ion-input-md,
.native-input.sc-ion-input-ios {
  margin-top: 10px;
}

.backBtn {
  position: fixed;
  top: 26px;
  left: 23px;
}

.icon-menu {
  height: 20px;
  width: 20px;
  color: white !important;
}

.custom-select {
  ion-label {
    flex: 0 1 50% !important;
  }

  ion-select,
  ionic-selectable {
    flex: 1 1 auto;
    display: flex;
    max-width: 100%;
    width: 100%;
    text-align-last: end;
    justify-content: flex-end;
  }
}

app-dni-photos {
  #video {
    width: 280px !important;
    height: 162px !important;
  }
}

app-dni-photos {
  #video {
    width: 280px !important;
    height: 162px !important;
  }
  ion-content,
  ion-content::part(background) {
    background-color: linear-gradient(180deg, #0047af 0%, #0361cf 100px) !important;
    background: linear-gradient(180deg, #0047af 0%, #0361cf 100px) !important;
  }
}

app-selfie {
  #video {
    width: 265px !important;
    height: 283px !important;
  }
  ion-content,
  ion-content::part(background) {
    background-color: linear-gradient(180deg, #0047af 0%, #0361cf 100px) !important;
    background: linear-gradient(180deg, #0047af 0%, #0361cf 100px) !important;
  }
}

app-step1 {
  .ng-option {
    white-space: pre-wrap !important;
    line-height: 16px !important;
    padding: 5px 15px !important;
    min-height: fit-content !important;
    text-transform: uppercase !important;
  }

  .ng-value {
    text-transform: uppercase !important;
  }
}

.ng-option {
  white-space: pre-wrap !important;
  line-height: 16px !important;
  padding: 5px 15px !important;
  min-height: fit-content !important;
  text-transform: uppercase !important;
}

.bg-menu {
  height: 100% !important;
  overflow: auto !important;
  background: url('assets/bg/bg-menu.svg') !important;
  background-position-y: bottom !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bg-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bg-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.searchbar-input.sc-ion-searchbar-md,
.searchbar-input.sc-ion-searchbar-ios {
  font-size: 13px;
  height: 30px;
}

.searchbar-search-icon.sc-ion-searchbar-md,
.searchbar-search-icon.sc-ion-searchbar-ios {
  top: 5px;
}

.modal-activities-confirm {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  .modal-wrapper {
    width: 310px;
    border-radius: 10px;
    height: 275px;
  }
}

app-contact-list {
  .searchbar-search-icon {
    left: 10px !important;
  }

  .searchbar-input {
    border-radius: 20px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    color: #bdbdbd !important;
    font-size: 20px !important;
  }
}

.monoDate {
  background-color: #08d1ff !important;
  p {
    color: black !important;
  }
}
.iibbDate {
  background-color: #0ab2e6 !important;
  p {
    color: black !important;
  }
}
.tsDate {
  background-color: #0361cf !important;
  p {
    color: black !important;
  }
}

app-down-ts {
  .native-textarea.sc-ion-textarea-md {
    padding: 10px !important;
    height: 100% !important;
  }
  .textarea-wrapper {
    border: 1px solid #c4c4c4;
    width: 100%;
    height: 100px;
  }
}

ion-label.multi-line {
  white-space: pre-wrap !important;
  display: flex !important;
  align-self: center !important;
}

/* Personalización del modal de ionic-selectable */
.ionic-selectable-modal-custom {
  padding: 25px;
}

/* Estilo de los elementos de la lista */
.ionic-selectable-item {
  padding-left: 10px;
  font-size: 10px;
  color: #333;
  display: flex;
  align-items: center;
  border-radius: 150px;
}

/* Icono junto al texto en cada elemento de la lista */
.ionic-selectable-item ion-icon {
  margin-right: 10px;
  font-size: 20px;
  color: #2c80b9;
}

/* Barra de título del modal */
.toolbar-title-default {
  color: #2c80b9;
}

/* Botón de cancelar */
.custom-cancel-button {
  color: #2c80b9;
}

// Fix css platform iOS
.plt-ios {
  ion-footer {
    padding-bottom: env(safe-area-inset-bottom) !important;
  }
}

ion-footer {
  div.version-app {
    padding: 5px !important;
    span {
      color: grey !important;
      font-size: 13px !important;
    }
  }
}

app-login {
  .native-input.sc-ion-input-md,
  .native-input.sc-ion-input-ios {
    padding-right: 15px !important;
  }
}

ion-header {
  ion-toolbar {
    ion-title {
      text-transform: uppercase;
    }
  }
}

ion-list ion-radio-group.button-group {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  ion-item {
    text-align: center;
    max-width: 350px;
    --min-height: 38px;
    --inner-padding-end: 0;

    ion-label {
      color: var(--ion-color-primary) !important;
      font-size: 16px !important;
      margin: 0;
    }

    ion-radio {
      width: 0;
      margin: 0;
    }

    ion-radio::part(container) {
      display: none;
    }

    &::part(native) {
      border-radius: 25px;
      border: 1px solid var(--ion-color-primary);
    }

    &.item-radio-checked {
      &::part(native) {
        background-color: var(--ion-color-primary);
      }

      ion-label {
        color: white !important;
      }
    }
  }
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

ion-item.readonly {
  --highlight-background: none;
}

.custom-alert-style .alert-wrapper {
  background-color: white; /* Fondo blanco */
  border-radius: 20px; /* Bordes redondeados */
  margin: 24px;
  max-width: 600px; /* Ancho máximo del alert */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25); /* Sombra */
}

.custom-alert-style .alert-title {
  font-size: 18px; /* Tamaño del texto del título */
  font-weight: bold; /* Negrita */
  text-align: center; /* Centrado */
}

.custom-alert-style .alert-message {
  font-size: 16px; /* Tamaño del texto del mensaje */
  text-align: center; /* Centrado */
  color: #333; /* Color de texto gris oscuro */
}

.custom-alert-style .alert-button {
  font-weight: bold; /* Negrita */

  background: #007bff; /* Color de fondo del botón (azul) */
  color: white; /* Color del texto */
  border-radius: 10px; /* Bordes redondeados */
  padding: 12px 16px;
  min-height: 24px; /* Altura mínima del botón */
  font-weight: bold; /* Negrita */
  font-size: 16px; /* Tamaño de la fuente */
  text-transform: uppercase; /* Texto en mayúsculas */
  width: auto; /* Ancho completo */
  justify-content: center;
  margin: auto; /* Centrado horizontal del texto */
}

.custom-alert-style .alert-button-group {
  justify-content: center; /* Asegura que el botón esté centrado */
}

.custom-alert-style .alert-button-role-cancel {
  margin-bottom: 20px; /* Espacio extra entre contenido y botón */
}

.custom-alert-style .alert-button-role-cerrar {
  margin-bottom: 20px; /* Espacio extra entre contenido y botón */
}
.padding-left-new-menu {
  padding-left: 300px;
  @media only screen and (max-width: 1080px) {
    padding-left: 0px;
  }
}
