.ionic-selectable-modal-custom {
  .modal-wrapper {
    width: 80%;
    height: 50%;

    .toolbar-title-default {
      display: none;
    }

    ion-label {
      white-space: pre-line;
      text-transform: capitalize;
    }

    ion-icon {
      font-size: 20px !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
  }
}
