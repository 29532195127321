// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
  --ion-color-primary: #0047af;
  --ion-color-primary-rgb: 0, 71, 175;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #003e9a;
  --ion-color-primary-tint: #1a59b7;

  --ion-color-secondary: #0361cf;
  --ion-color-secondary-rgb: 3, 97, 207;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0355b6;
  --ion-color-secondary-tint: #1c71d4;

  --ion-color-tertiary: #0ab2e6;
  --ion-color-tertiary-rgb: 10, 178, 230;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #099dca;
  --ion-color-tertiary-tint: #23bae9;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffd25e;
  --ion-color-warning-rgb: 255, 210, 94;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b953;
  --ion-color-warning-tint: #ffd76e;

  --ion-color-danger: #be1937;
  --ion-color-danger-rgb: 190, 25, 55;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a71630;
  --ion-color-danger-tint: #c5304b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  .ion-color-ts-blue-logo {
    --ion-color-base: #0047af;
  }
  .ion-color-ts-blue {
    --ion-color-base: #0361cf;
  }
  .ion-color-ts-light-blue {
    --ion-color-base: #cae9ff;
  }
  .ion-color-ts-light-blue-logo {
    --ion-color-base: #0ab2e6;
  }
  .ion-color-ts-cyan {
    --ion-color-base: #08d1ff;
  }
  .ion-color-ts-red {
    --ion-color-base: #be1937;
  }
  .ion-color-ts-green {
    --ion-color-base: #04ad5c;
  }
  .ion-color-ts-black {
    --ion-color-base: #000000;
  }
  .ion-color-ts-gray1 {
    --ion-color-base: #2e363a;
  }
  .ion-color-ts-gray2 {
    --ion-color-base: #6c777c;
  }
  .ion-color-ts-gray3 {
    --ion-color-base: #a7aeb1;
  }
  .ion-color-ts-gray4 {
    --ion-color-base: #ccd1d3;
  }
  .ion-color-ts-gray5 {
    --ion-color-base: #e1e4e5;
  }
  .ion-color-ts-yellow {
    --ion-color-base: #ffd25e;
  }

  --ion-color-gradient: linear-gradient(300.18deg, rgba(3, 97, 207, 0.15) 3.11%, rgba(8, 209, 255, 0.1) 99.6%);

  --ion-color-btn-add: #0ab2e6;
  --ion-color-btn-add-rgb: 10, 178, 230;
  --ion-color-btn-add-contrast: #ffffff;
  --ion-color-btn-add-contrast-rgb: 255, 255, 255;
  --ion-color-btn-add-shade: #099dca;
  --ion-color-btn-add-tint: #23bae9;
}

.ion-color-btn-add {
  --ion-color-base: var(--ion-color-btn-add);
  --ion-color-base-rgb: var(--ion-color-btn-add-rgb);
  --ion-color-contrast: var(--ion-color-btn-add-contrast);
  --ion-color-contrast-rgb: var(--ion-color-btn-add-contrast-rgb);
  --ion-color-shade: var(--ion-color-btn-add-shade);
  --ion-color-tint: var(--ion-color-btn-add-tint);
}
