.margin-l-10 {
  margin-left: 10px;
}

.margin-r-10 {
  margin-right: 10px;
}

.margin-l-20 {
  margin-left: 20px;
}

.margin-r-20 {
  margin-right: 20px;
}

.margin-t--15 {
  margin-top: -15px !important;
}

.margin-t--20 {
  margin-top: -20px !important;
}

.margin-t--5 {
  margin-top: -5px !important;
}

.margin-t-0 {
  margin-top: 0 !important;
}

.margin-t-5 {
  margin-top: 5px !important;
}

.margin-t-10 {
  margin-top: 10px !important;
}

.margin-t-15 {
  margin-top: 15px !important;
}

.margin-t-20 {
  margin-top: 20px;
}

.margin-t-25 {
  margin-top: 25px;
}

.margin-t-50 {
  margin-top: 50px;
}

.margin-b--15 {
  margin-bottom: -15px !important;
}

.margin-b-5 {
  margin-bottom: 5px !important;
}

.margin-b-10 {
  margin-bottom: 10px !important;
}

.margin-b-20 {
  margin-bottom: 20px !important;
}

.margin-b-25 {
  margin-bottom: 25px;
}

.margin-b-40 {
  margin-bottom: 40px;
}

.padding-l-0 {
  padding-left: 0 !important;
}

.padding-l-10 {
  padding-left: 10px;
}

.padding-l-25 {
  padding-left: 25px !important;
}

.padding-r-0 {
  padding-right: 0 !important;
}

.padding-r-20 {
  padding-right: 20px !important;
}

.padding-t-7 {
  padding-top: 7px !important;
}

.padding-t-10 {
  padding-top: 10px !important;
}

.padding-t-15 {
  padding-top: 15px !important;
}

.padding-t-20 {
  padding-top: 20px !important;
}

.padding-b-10 {
  padding-bottom: 10px !important;
}

.padding-b-20 {
  padding-bottom: 20px !important;
}

.padding-h-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.padding-h-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padding-h-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.padding-h-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.w-100 {
  width: 100%;
}

// INIT - Font
.f-regular {
  font-weight: 400 !important;
}

.f-bold {
  font-weight: bold !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

// END- Font
.text-underline {
  text-decoration: underline !important;
}

.d-flex {
  display: flex;
}

.uppercase {
  text-transform: uppercase !important;
}

.p-0 {
  padding: 0 !important;
}

.txt-left {
  text-align: left !important;
}

.txt-center {
  text-align: center !important;
}

.custom-disabled {
  cursor: default !important;
  opacity: 0.5 !important;
  pointer-events: none !important;
}
